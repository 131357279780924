var render = function () {
  var _vm$meData, _vm$meData2, _vm$agenciesData, _vm$meData3, _vm$meData4, _vm$agenciesData2, _vm$agenciesData3, _vm$meData5, _vm$agenciesData4, _vm$agenciesData5;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('IAmOverlay', {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c(_vm.agenciesData === undefined ? 'div' : 'b-card', {
    tag: "component"
  }, [_c('b-alert', {
    attrs: {
      "variant": "danger",
      "show": _vm.agenciesData === undefined
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v(" " + _vm._s(_vm.$t('agency.errFetchAgency')) + " ")]), _c('div', {
    staticClass: "alert-body"
  }, [_vm._v(" " + _vm._s(_vm.$t('agency.notFundAgencyById_1')) + " "), _c('b-link', {
    staticClass: "alert-link",
    attrs: {
      "to": {
        name: 'apps-agencies-list'
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('agency.notFundAgencyById_2')) + " ")]), _vm._v(" " + _vm._s(_vm.$t('agency.notFundAgencyById_3')) + " ")], 1)]), _vm.agenciesData ? _c('div', {
    staticClass: "tabs"
  }, [_c('b-nav', {
    attrs: {
      "pills": ""
    }
  }, [_c('b-nav-item', {
    attrs: {
      "to": "#",
      "active": _vm.$route.hash === '#' || _vm.$route.hash === ''
    }
  }, [_c('div', {
    staticClass: "d-flex-center"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "UserIcon",
      "size": "16"
    }
  }), _c('span', {
    staticClass: "d-none d-sm-inline"
  }, [_vm._v(_vm._s(_vm.$t('general')))])], 1)]), _c('b-nav-item', {
    attrs: {
      "to": "#employees",
      "active": _vm.$route.hash === '#employees'
    }
  }, [_c('div', {
    staticClass: "d-flex-center"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "UsersIcon",
      "size": "16"
    }
  }), _c('span', {
    staticClass: "d-none d-sm-inline"
  }, [_vm._v(_vm._s(_vm.$t('employees')))])], 1)]), _c('b-nav-item', {
    attrs: {
      "to": "#manager",
      "active": _vm.$route.hash === '#manager'
    }
  }, [_c('div', {
    staticClass: "d-flex-center"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "UserIcon",
      "size": "16"
    }
  }), _c('span', {
    staticClass: "d-none d-sm-inline"
  }, [_vm._v(_vm._s(_vm.$t('agency.manager')))])], 1)]), ((_vm$meData = _vm.meData) === null || _vm$meData === void 0 ? void 0 : _vm$meData.type) === 'ADM' ? _c('b-nav-item', {
    attrs: {
      "to": "#bank-accounts",
      "active": _vm.$route.hash === '#bank-accounts'
    }
  }, [_c('div', {
    staticClass: "d-flex-center"
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "dollar",
      "size": "16"
    }
  }), _c('span', {
    staticClass: "d-none d-sm-inline"
  }, [_vm._v(_vm._s(_vm.$t('bankAccountsInfo')))])], 1)]) : _vm._e(), !_vm.isProduction ? _c('b-nav-item', {
    attrs: {
      "to": "#company",
      "active": _vm.$route.hash === '#company'
    }
  }, [_c('div', {
    staticClass: "d-flex-center"
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "buildingOutline",
      "size": "16"
    }
  }), _c('span', {
    staticClass: "d-none d-sm-inline"
  }, [_vm._v(_vm._s(_vm.$t('agency.company.title')))])], 1)]) : _vm._e(), ['ADM', 'OPE'].includes((_vm$meData2 = _vm.meData) === null || _vm$meData2 === void 0 ? void 0 : _vm$meData2.type) ? _c('b-nav-item', {
    attrs: {
      "to": "#web-config",
      "active": _vm.$route.hash === '#web-config'
    }
  }, [_c('div', {
    staticClass: "d-flex-center"
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "internet",
      "size": "16"
    }
  }), _c('span', {
    staticClass: "d-none d-sm-inline"
  }, [_vm._v(_vm._s(_vm.$t('agency.webConfig.title')))])], 1)]) : _vm._e()], 1), _c('div', {
    staticClass: "tab-content"
  }, [_c('div', {
    staticClass: "p-0",
    class: ['tab-pane', {
      'active': _vm.$route.hash === '#' || _vm.$route.hash === ''
    }]
  }, [_c('AgenciesEditTabAccount', {
    attrs: {
      "agencies-data": _vm.agenciesData,
      "loading-prop": _vm.loading
    },
    on: {
      "update:loadingProp": function updateLoadingProp($event) {
        _vm.loading = $event;
      },
      "update:loading-prop": function updateLoadingProp($event) {
        _vm.loading = $event;
      },
      "refetchAgency": _vm.refetchAgency
    }
  })], 1), _c('div', {
    staticClass: "p-0",
    class: ['tab-pane', {
      'active': _vm.$route.hash === '#employees'
    }]
  }, [_c('AgenciesEditTabEmployee', {
    attrs: {
      "agencies-data": _vm.agenciesData,
      "loading-prop": _vm.loading
    },
    on: {
      "update:loadingProp": function updateLoadingProp($event) {
        _vm.loading = $event;
      },
      "update:loading-prop": function updateLoadingProp($event) {
        _vm.loading = $event;
      },
      "refetchAgency": _vm.refetchAgency
    }
  })], 1), _c('div', {
    staticClass: "p-0",
    class: ['tab-pane', {
      'active': _vm.$route.hash === '#manager'
    }]
  }, [_c('AgenciesManagerTab', {
    attrs: {
      "manager-employees": (_vm$agenciesData = _vm.agenciesData) === null || _vm$agenciesData === void 0 ? void 0 : _vm$agenciesData.managers.map(function (item) {
        return item.employee;
      })
    }
  })], 1), ((_vm$meData3 = _vm.meData) === null || _vm$meData3 === void 0 ? void 0 : _vm$meData3.type) === 'ADM' ? _c('div', {
    staticClass: "p-0",
    class: ['tab-pane', {
      'active': _vm.$route.hash === '#bank-accounts'
    }]
  }, [_c('AccountSettingBankAccountsList', {
    attrs: {
      "agency-data": (_vm$meData4 = _vm.meData) === null || _vm$meData4 === void 0 ? void 0 : _vm$meData4.agency
    }
  })], 1) : _vm._e(), !_vm.isProduction ? _c('div', {
    staticClass: "p-0",
    class: ['tab-pane', {
      'active': _vm.$route.hash === '#company'
    }]
  }, [_c('AgenciesEditCompany', {
    attrs: {
      "agency-company": (_vm$agenciesData2 = _vm.agenciesData) === null || _vm$agenciesData2 === void 0 ? void 0 : _vm$agenciesData2.company,
      "agency-id": (_vm$agenciesData3 = _vm.agenciesData) === null || _vm$agenciesData3 === void 0 ? void 0 : _vm$agenciesData3.id
    },
    on: {
      "refetchAgency": _vm.refetchAgency
    }
  })], 1) : _vm._e(), ['ADM', 'OPE'].includes((_vm$meData5 = _vm.meData) === null || _vm$meData5 === void 0 ? void 0 : _vm$meData5.type) ? _c('div', {
    staticClass: "p-0",
    class: ['tab-pane', {
      'active': _vm.$route.hash === '#web-config'
    }]
  }, [_c('AgenciesEditWebConfig', {
    attrs: {
      "agency-web-config": (_vm$agenciesData4 = _vm.agenciesData) === null || _vm$agenciesData4 === void 0 ? void 0 : _vm$agenciesData4.agencyConfig,
      "agency-id": (_vm$agenciesData5 = _vm.agenciesData) === null || _vm$agenciesData5 === void 0 ? void 0 : _vm$agenciesData5.id
    },
    on: {
      "refetchAgency": function refetchAgency() {
        _vm.refetchAgency();
        _vm.refetchAgencyConfig();
      }
    }
  })], 1) : _vm._e()])], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }